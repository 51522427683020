import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { SizedBox } from "./SizedBox";
import Emblem from "../components/media/GolferzEmblem.png"
import { BrowserView, MobileView } from "react-device-detect";

const NavWrapper = styled.div`
text-align: center;
align-items: center;
width: "100%";
background-color: rgba(0,50,0,0.1);
position:"absolute";
top: "0px";
padding: 10px;
`
const MobileNavWrapper = styled.div`
text-align: center;
align-items: center;
width: "100%";
background-color: rgba(0,50,0,0.1);
position:"absolute";
top: "0px";
padding: 1px;
`

const GolferzEmblem = styled.img`
  width: 70px;
  justify-content: right;
  display: inline-block;
  
`;

const MobileEmblem = styled.img`
  width: 48px;
  height: 48px;
  justify-content: right;
  display: inline-block;
  padding-top:2%;
  margin: auto;
`;

const InnerWrapper = styled.nav`
  width: 80%;
  vertical-align: middle;
`;

const MobileInnerWrapper = styled.nav`
  width: 95%;
  vertical-align: middle;
`;

const TitleWrapper = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: black;
  vertical-align: middle;
  display: inline-block;
`;

const Links = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: black;
  vertical-align: middle;
  display: inline-block;
  font-size: "24px";
  color: black;
  font-weight: bold;
  text-align: center;
  margin: auto;
  padding-top:2%;
`;

const MobileLinks = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: black;
  vertical-align: middle;
  display: inline-block;
  font-size: "1px";
  color: black;
  text-align: center;
  margin: auto;
  padding-top:3%;
`;

const WordWrapper = styled.div`
  font-size: "32px";
  color: black;
  font-weight: bold;
  text-align: center;
  margin: auto;
  padding-top:4%;
`

const MobileWordWrapper = styled.div`
  font-size: "4px";
  color: black;
  font-weight: bold;
  text-align: center;
  margin: auto;
  padding-top:3%;
`

export default function NavBar({collab, url})
{
    return(
        <>
    <BrowserView>
    <NavWrapper className="d-flex justify-content-center">
        <InnerWrapper className="d-flex justify-content-between">
                    
                    <TitleWrapper href={"https://golferz.club"} className="d-flex">

                        <GolferzEmblem src={Emblem}/>
                        <SizedBox width={15}/>
                        <WordWrapper className="d-flex justify-content-center">
                            <h1>Golferz.club</h1>
                        </WordWrapper>
                    </TitleWrapper>
                    {collab ? <>
                      <WordWrapper className="d-flex justify-content-center">
                            <h3>X</h3>
                        </WordWrapper>
                      <TitleWrapper href={url}>
                        <img src={"/collabs/" + collab + ".png"} height={"65px"}/>
                      </TitleWrapper>
                    </> : <>
                    <SizedBox width={"10%"}/>
            <Links href={"/"}><h5>Minting Greens</h5></Links>
            <Links href={"/media"}><h5>Media Range</h5></Links>
            <Links href={"/traits"}><h5>Trait Explorer</h5></Links>
                    </>}

        
        </InnerWrapper>
    </NavWrapper>
    </BrowserView>

    <MobileView style={{ fontSize: '10px' }}>
    <MobileNavWrapper className="d-flex justify-content-center">
        <MobileInnerWrapper className="d-flex justify-content-between">
                    <TitleWrapper href={"https://golferz.club"} className="d-flex">
                        <MobileEmblem src={Emblem}/>
                        <SizedBox width={15}/>
                        <MobileWordWrapper className="d-flex justify-content-center">
                            <h5>Golferz.club</h5>
                        </MobileWordWrapper>
                    </TitleWrapper>
                    {collab ? <>
                      <WordWrapper className="d-flex justify-content-center">
                            <h3>X</h3>
                        </WordWrapper>
                      <TitleWrapper href={url}>
                        <img src={"/collabs/" + collab + ".png"} height={"50px"}/>
                      </TitleWrapper>
                    </> : <>
                    <SizedBox width={"10%"}/>
            <Links href={"/"}><p>Minting Greens</p></Links>
            <Links href={"/media"}><p>Meme Range</p></Links>
            <Links href={"/traits"}><p>Trait Explorer</p></Links>
                    </>}
        </MobileInnerWrapper>
    </MobileNavWrapper>
    </MobileView>
    </>
    )
}