import { Col, Row } from "react-bootstrap";
import SocialButton from "./SocialButton";

export default function SocialBar({width}) {
    return(
        <div style={{width: width ? width : "100%", margin: "auto"}}>
            <Row>
                <h3>Follow for updates</h3>
            </Row>
            <Row>
                {/*<Col>
                    <SocialButton width="100%" type={"telegram"} />
                </Col>*/}
                <Col>
                    <SocialButton width="100%" type={"discord"} />
                </Col>
                <Col>
                    <SocialButton width="100%" type={"twitter"} />
                </Col>
            </Row>
        </div>
    )
}