import logo from './logo.svg';
import './App.css';
import Mint from './pages/Mint';
import Media from "./pages/Media"
import Goerli from "./pages/Goerli";
import Traits from './pages/Traits';
import TraitsWoman from './pages/TraitsWoman';
import NFT from './pages/NFT';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import GolfTag from './pages/GolfTag';


function App() {


  if(window.location.host.split(".")[0] == "goerli")
  {
   return(
    <Routes>
      <Route path="/" element={<Goerli />} />
    </Routes>
   ) 
  }
  return (
    
    <Routes>
      <Route path="/" element={<Mint />} />
      <Route path="/nfts" element={<NFT />} />
      <Route path="/goerli" element={<Goerli />} />
      <Route path="/media" element={<Media />} /> 
      <Route path="/golftag" element={<GolfTag />} />
      <Route path="/traits" element={<Traits />} />
      <Route path="/companiontraits" element={<TraitsWoman />} />
    </Routes>

  );
}

export default App;
