import { useParams } from "react-router-dom";
export default function NFT() {
    const params = useParams();
    console.log(params.id);
    var path = process.env.PUBLIC_URL + "/nfts/" + params.id + ".png";
    console.log(path);

    return(
        <div>
            <img src={path} alt="not found"/>
        </div>
    )
}