import Sticker from "./Sticker";
import { Col, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
export default function StickerPack({type, body, eyes, shirt, tattoo, mouth})
{
    const [stickers, setStickers] = useState([]);
    useEffect(() => {
        fetch('/sticker pack/stickers.json')
            .then(response => response.json())
            .then(data => {
                type === "woman" ? setStickers(data.golferzwomen) : setStickers(data.golferz);
                
            })
            .catch(error => console.error("Failed to load stickers:", error));
    }, []);
    let stickerTiles = [];
    stickers.forEach(stickerName =>{
          stickerTiles.push(
            <Col>
              <Sticker name={stickerName} body={body} eyes={eyes} shirt={shirt} tattoo={tattoo} mouth={mouth} />
            </Col>
          );
    })


    let rows = [];
    let cols = [];

    for(let i = 0; i < stickerTiles.length; i++)
    {
        cols.push(stickerTiles[i]);

        if(cols.length == 5)
        {
            rows.push(
                <Row>
                    {cols}
                </Row>);
            cols = [];
        }

    }
    for(let j = cols.length; j < 5; j++)
    {
        cols.push(<Col></Col>);
    }
    rows.push(<Row>{cols}</Row>);

    return(
        <>
            {rows}
        </>
    )
}