import {Col, Nav, Row} from "react-bootstrap";
import { readString } from "react-papaparse";
import { SizedBox } from "../components/SizedBox";
import SocialBar from "../components/SocialBar";
import { BrowserView, MobileView } from "react-device-detect";
import { useEffect, useState } from "react";
import mergeImages from 'merge-images';
import ImageTray from "../components/ImageImporter";
import NavBar from "../components/NavBar";
import styled from "styled-components";
import csvFile from "../contracts/metadata.csv";
import contract from "../contracts/golferzgoerli.json";
import StickerPack from "../components/StickerPack";

const contractAddress = "0x5308a8c8a72c3ef8940ac929684b7065a1f732b1";
const abi = contract.abi;

const Title = styled.h1`
font-size: 3.5em;
`
export default function Traits() {
    const none = "/memeparts/nft parts/tattoo/1none.png";
    const [currentAccount, setCurrentAccount] = useState(null);
    const [banner, setBanner] = useState("Connect your Metamask wallet!");
    const [body, setBody] = useState("/memeparts/nft parts/body/Standard Issue.png");
    const [background, setBackground] = useState("/memeparts/nft parts/bg/bg_Fairway.png");
    const [tattoo, setTattoo] = useState(none);
    const [eyes, setEyes] = useState("/memeparts/nft parts/eyes/Standard.png");
    const [mouth, setMouth] = useState("/memeparts/nft parts/mouth/Grin.png");
    const [shirt, setShirt] = useState(none);
    const [image, setImage] = useState(null);
    const [partsTray, setPartsTray] = useState(null);
    const [b64, setB64] = useState(null);
    const [eventButton, setEventButton] = useState(null);
    const [link, setLink] = useState("https://opensea.io/assets/ethereum/" + contractAddress + "/");


    const checkWalletConnection = async () => {
        const { ethereum } = window;
        if(!ethereum) {
            console.log("You need to have Metamask installed!");
            return;
        } else {
            console.log("Metamask is installed.");
        }

        const accounts = await ethereum.request({method: "eth_accounts" });

        if(accounts.length > 0) {
            const account = accounts[0];
            setCurrentAccount(account);
            setBanner(currentAccount);
        }
    }

    const connectWalletHandler = async () => {
        const { ethereum } = window;

        if(!ethereum) {
            console.log("You need to have Metamask installed!");
        } 

        try {
            const accounts = await ethereum.request({method: "eth_requestAccounts"});
            setCurrentAccount(accounts[0]);
        } catch (e) {
            console.log(e);
        }
    }

    const changePartsTray = (dir) => {
            setPartsTray(<ImageTray dir={dir} onClick={(item) => {updateItem({item});}} bg={background}/>);
    }

    const updateItem = ({item}) => 
    {
        let itemSlot = "";
        let slot = item.split("/");
        itemSlot = slot[slot.length - 2];
        switch(itemSlot){
            case "bg":
                setBackground(item);
                break;

            case "body":
                setBody(item);
                break;

            case "eyes":
                setEyes(item);
                break;

            case "mouth":
                setMouth(item);
                break;

            case "shirt":
                setShirt(item);
                break;

            case "tattoo":
                setTattoo(item);
                break;

            default:
                console.log(item);
                break;
                    
        }
    }

    const updateImage = async () => {
        let b64t = "";
        b64t = await mergeImages([background, body, tattoo, eyes, mouth, shirt]);
        setB64(b64t);
        setImage(<img src={b64t} style={{width: "100%", display: "block"}} alt={"Golferz"}/>);
    }



    const searchCSV = async (background, body, tattoo, eyes, mouth, shirt) => {
        background = background.split("/")[4].slice(3,-4);
        body = body.split("/")[4].slice(0,-4);
        tattoo = tattoo.split("/")[4].slice(0,-4);
        eyes = eyes.split("/")[4].slice(0,-4);
        mouth = mouth.split("/")[4].slice(0,-4);
        shirt = shirt.split("/")[4].slice(0,-4);
        if(tattoo == "1none")
        {
            tattoo = "none";
        }
        if(shirt == "1none")
        {
            shirt = "none";
        }

        const response = await fetch(csvFile); // Assuming csvFile is the path to your CSV file
        const csvText = await response.text(); // Read the CSV file as text
      
        const csvData = readString(csvText).data; // Parse the CSV data
      
        for (let i = 0; i < csvData.length; i++) {
          const row = csvData[i];
          const [number, bg, bd, tt, ey, mo, sh] = row;
      
          if (
            bg === background &&
            bd === body &&
            tt === tattoo &&
            ey === eyes &&
            mo === mouth &&
            sh === shirt
          ) {
            setBanner("This is Golferz#" + number);
            return number; // Return the number if all traits match
          }
        }
        setBanner("This Golferz does not appear in the collection");
        return null; // Return null if no matching row is found
      };


    useEffect(() => {
        checkWalletConnection();
        if(currentAccount)
        {
        }
    }, [currentAccount])

    useEffect(() => {
        updateImage();
        const number = searchCSV(background, body, tattoo, eyes, mouth, shirt);
    }, [background, body, eyes, mouth, tattoo, shirt])

    useEffect(() => {
        updateImage();
    }, [])

        return(
                <>
                <BrowserView>
                    <div style={{width: "60%", backgroundColor:"rgba(255,255,255,0.4", margin:"auto", height: "auto", position:"absolute", top: "0px", left: "20%"}}>
                    <NavBar />
                    <div style={{padding: "25px"}}>
                        <Col>
                            <Row>
                                <Title>Trait Explorer</Title>
                                <h4>Click on the golfer to save your creation!</h4>
                            </Row>
                            <Col>
                                </Col>
                                <SizedBox height={"25px"} />
                               
                            <Row>
                                <Col>
                                { image ? <h4></h4> : <></>}
                                <div style={{ padding: "5px", width: "30%", margin: "auto"}}>
                                            
                                        <div style={{position: "relative"}}>
                                        <a href={b64} download="Golferz.png">{image} </a>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                            </Row>
                            <SizedBox height={"25px"} />
                            <Row>
                            <Col>
                            <div style={{ padding: "5px", backgroundColor: "#C0D6A2", width: "80%", margin: "auto", zIndex: "-100"}}>
                                {image ? 
                                <Col>
                                    <Row>
                                    <Col>
                                    <img src={"ItemButtons/nft_bg.png"} width={"100%"} style={{cursor:"pointer"}} onClick={() => {changePartsTray("nft parts/bg");}}/>
                                    </Col>
                                    <Col>
                                    <img src={"ItemButtons/nft_body.png"} width={"100%"} style={{cursor:"pointer"}} onClick={() => {changePartsTray("nft parts/body");}}/>
                                    </Col>
                                    <Col>
                                    <img src={"ItemButtons/nft_tattoo.png"} width={"100%"} style={{cursor:"pointer"}} onClick={() => {changePartsTray("nft parts/tattoo");}}/>
                                    </Col>
                                    <Col>
                                    <img src={"ItemButtons/nft_eyes.png"} width={"100%"} style={{cursor:"pointer"}} onClick={() => {changePartsTray("nft parts/eyes");}}/>
                                    </Col>
                                    <Col>
                                    <img src={"ItemButtons/nft_mouth.png"} width={"100%"} style={{cursor:"pointer"}} onClick={() => {changePartsTray("nft parts/mouth");}}/>
                                    </Col>
                                    <Col>
                                    <img src={"ItemButtons/nft_shirt.png"} width={"100%"} style={{cursor:"pointer"}} onClick={() => {changePartsTray("nft parts/shirt");}}/>
                                    </Col>
                                    </Row>
                                    <SizedBox height={"25px"} />
                                    <Row>
                                        <Col>
                                            {partsTray}
                                        </Col>
                                    </Row>
                                </Col> 
                                : <p></p>}
                            </div>
                            </Col>
                            </Row>
                            
                    {/* STICKER TESTING */}
                    <StickerPack type="Golferz" body={body.split('/')[4]} eyes={eyes.split("/")[4]} tattoo={tattoo.split("/")[4]} mouth={mouth.split("/")[4]} shirt={shirt.split("/")[4]} />
                            <SizedBox height={"50px"} />
                            <SizedBox height={"75px"} />
                            <SocialBar width={"20%"} />
                            <SizedBox height="25px" />
                        </Col>
                    </div>

                    </div>
                    </BrowserView>

                    <MobileView>
                    <div style={{width: "90%", backgroundColor:"rgba(255,255,255,0.4", margin:"auto", height: "auto", position:"absolute", top: "0px", left: "5%"}}>
                    <NavBar />
                    <div style={{padding: "5px"}}>
                        <Col>
                            <Row>
                                <h1>Trait Explorer</h1>
                                <h5>Tap on the golfer to save your creation!</h5>
                            </Row>
                            <Col>
                                </Col>
                                <SizedBox height={"25px"} />
                               
                            <Row>
                                <Col>
                                { image ? <h4></h4> : <></>}
                                <div style={{ padding: "5px", width: "30%", margin: "auto"}}>
                                            
                                        <div style={{position: "relative"}}>
                                        <a href={b64} download="Golferz.png">{image} </a>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                            </Row>
                            <SizedBox height={"25px"} />
                            <Row>
                            <Col>
                            <div style={{ padding: "5px", backgroundColor: "#C0D6A2", width: "100%", margin: "auto", zIndex: "-100"}}>
                                {image ? 
                                <Col>
                                    <Row>
                                    <Col>
                                    <img src={"ItemButtons/nft_bg.png"} width={"100%"} style={{cursor:"pointer"}} onClick={() => {changePartsTray("nft parts/bg");}}/>
                                    </Col>
                                    <Col>
                                    <img src={"ItemButtons/nft_body.png"} width={"100%"} style={{cursor:"pointer"}} onClick={() => {changePartsTray("nft parts/body");}}/>
                                    </Col>
                                    <Col>
                                    <img src={"ItemButtons/nft_tattoo.png"} width={"100%"} style={{cursor:"pointer"}} onClick={() => {changePartsTray("nft parts/tattoo");}}/>
                                    </Col>
                                    <Col>
                                    <img src={"ItemButtons/nft_eyes.png"} width={"100%"} style={{cursor:"pointer"}} onClick={() => {changePartsTray("nft parts/eyes");}}/>
                                    </Col>
                                    <Col>
                                    <img src={"ItemButtons/nft_mouth.png"} width={"100%"} style={{cursor:"pointer"}} onClick={() => {changePartsTray("nft parts/mouth");}}/>
                                    </Col>
                                    <Col>
                                    <img src={"ItemButtons/nft_shirt.png"} width={"100%"} style={{cursor:"pointer"}} onClick={() => {changePartsTray("nft parts/shirt");}}/>
                                    </Col>
                                    </Row>
                                    <SizedBox height={"25px"} />
                                    <Row>
                                        <Col>
                                            {partsTray}
                                        </Col>
                                    </Row>
                                </Col> 
                                : <p></p>}
                            </div>
                            </Col>
                            </Row>
                            <SizedBox height={"50px"} />
                            <SizedBox height={"75px"} />
                            <SocialBar width={"40%"} />
                            <SizedBox height="25px" />
                        </Col>
                    </div>
                    </div>
                    </MobileView>
            </>
        )
}
