import {Col, Nav, Row} from "react-bootstrap";
import GolferzGif from "../components/GolferzGif";
import MediumButton from "../components/MediumButton";
import { SizedBox } from "../components/SizedBox";
import SocialBar from "../components/SocialBar";
import { BrowserView, MobileView } from "react-device-detect";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import mergeImages from 'merge-images';
import ImageTray from "../components/ImageImporter";
import NavBar from "../components/NavBar";
import styled from "styled-components";

import contract from "../contracts/golferzgoerli.json";

const contractAddress = "0x5308a8c8a72c3ef8940ac929684b7065a1f732b1";
const abi = contract.abi;

const Title = styled.h1`
font-size: 3.5em;
`
export default function Media() {
    const none = "/memeparts/nft parts/tattoo/1none.png";
    const [currentAccount, setCurrentAccount] = useState(null);
    const [banner, setBanner] = useState("Connect your Metamask wallet!");
    const [nftList, setNftList] = useState(null);
    const [currentNft, setCurrentNft] = useState(null);
    const [body, setBody] = useState(null);
    const [background, setBackground] = useState(null);
    const [tattoo, setTattoo] = useState(null);
    const [eyes, setEyes] = useState(null);
    const [mouth, setMouth] = useState(null);
    const [shirt, setShirt] = useState(null);
    const [image, setImage] = useState(null);
    const [mug, setMug] = useState(none);
    const [hand, setHand] = useState(null);
    const [hand2, setHand2] = useState(null);
    const [hat,setHat] = useState(none);
    const [partsTray, setPartsTray] = useState(null);
    const [b64, setB64] = useState(null);
    const [bg, setBg] = useState(null);
    const [effect, setEffect] = useState(none);
    const [eventButton, setEventButton] = useState(null);
    const [currentChain, setCurrentChain] = useState(null);
    const [currentTray, setCurrentTray] = useState(null);


    const checkWalletConnection = async () => {
        const { ethereum } = window;
        if(!ethereum) {
            console.log("You need to have Metamask installed!");
            return;
        } else {
            console.log("Metamask is installed.");
        }

        const accounts = await ethereum.request({method: "eth_accounts" });

        if(accounts.length > 0) {
            const account = accounts[0];
            setCurrentAccount(account);
            setBanner(currentAccount);
        }
        await ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x1' }],
         })
        setCurrentChain("0x1");
    }

    const connectWalletHandler = async () => {
        const { ethereum } = window;

        if(!ethereum) {
            console.log("You need to have Metamask installed!");
        } 

        try {
            const accounts = await ethereum.request({method: "eth_requestAccounts"});
            setCurrentAccount(accounts[0]);
        } catch (e) {
            console.log(e);
        }
    }

    const changePartsTray = (dir) => {
            setCurrentTray(dir);
            console.log(currentTray);
    }

    useEffect(() => {
        console.log(currentTray); // Will log the updated value
        let currentItem = null;
        switch(currentTray)
        {
            
        case "mug":
            currentItem = mug;        
            break;
        case "coin":
            currentItem = mug;           
            break;
        case "hat":
            currentItem = hat;           
            break;
        case "backgrounds":
            currentItem = background;          
            break;
        case "effects":
            currentItem = effect;           
            break;

        case "halloween":
            currentItem = null;           
            break;
        case "christmas":
            currentItem = null;          
            break;
        }

        setPartsTray(<ImageTray currentItem={currentItem} dir={currentTray} onClick={(item) => {updateItem({item});}} bg={bg}/>);
      }, [currentTray]);
    const updateItem = ({item}) => 
    {
        let itemSlot = currentTray;
        console.log(currentTray);
        if(currentTray == "halloween" || currentTray == "christmas")
        {
            let slot = item.split("/");
            itemSlot = slot[slot.length - 1].split("_")[0];
        }
        switch(itemSlot){
            case "hat":
                if(item.includes("none"))
                {
                    setHat(none)
                }
                else
                {
                    setHat(item);
                }
                break;
                
                case "sats":
                case "coin":
                    if(item.includes("none"))
                {
                    setMug(none)
                }
                else
                {
                    setMug(item);
                }
                    break;

                case "mug":
                    if(item.includes("none"))
                {
                    setMug(none)
                }
                else
                {
                    setMug(item);
                }
                    break;
                    case "effect":
                        if(item.includes("none"))
                    {
                        setEffect(none)
                    }
                    else
                    {
                        setEffect(item);
                    }
                        break;

                    case "backgrounds":
                    {
                        if(item.includes("none"))
                    {
                        setBackground(none)
                    }
                    else
                    {
                        setBackground(item);
                    }
                    break;
                    }
                        break;
        }
    }

    const getNFTs = async () => {
        try{
            const { ethereum } = window;
            const provider = new ethers.providers.Web3Provider(ethereum);
            const nftcontract = new ethers.Contract(contractAddress, abi, provider)
            console.log(currentAccount);
            let ownedNfts = await nftcontract.tokensOfOwner(currentAccount);
            let newNftList = [];
            //get list of nft images
            ownedNfts.forEach(el => {
                let num = parseInt(el._hex, 16);
                let numstr = "";
                if(num < 10)
                {
                    numstr = "000" + num;
                } 
                else if(num < 100)
                {
                    numstr = "00" + num;
                }
                else if(num < 1000)
                {
                    numstr = "0" + num;
                }
                newNftList.push(
                    <Col>
                        <img src={"https://storage.googleapis.com/golferz-nft/nfts/" + numstr + ".png"} style={{cursor: "pointer"}} width={"100%"} onClick={() => getNFTParts(num)}/>
                    </Col>
                )

                let rows = [];
                let cols = [];

                for(let i = 0; i < newNftList.length; i++)
                {
                    cols.push(newNftList[i]);

                    if(cols.length == 5)
                    {
                        rows.push(
                            <Row>
                                {cols}
                            </Row>);
                        cols = [];
                    }

                }
                for(let j = cols.length; j < 5; j++)
                {
                    cols.push(<Col></Col>);
                }
                rows.push(<Row>{cols}</Row>);
                setNftList(<div>{rows}</div>);
            });
            } catch (e) {
                console.log(e);
            }
    }

    const getNFTParts = async (num) => {
        try {
            console.log(num);
            let response = await fetch("https://storage.googleapis.com/golferz-nft/json/" + num);
            let json = await response.json();
            if(json.attributes[0].key !== "1/1")
            {
                console.log(json.attributes);
                setBackground("/memeparts/nft parts/bg/bg_" + json.attributes[0].value + ".png");
                setBg("/memeparts/nft parts/bg/bg_" + json.attributes[0].value + ".png");
                setBody("/memeparts/nft parts/body/" + json.attributes[1].value + ".png");
                setHand("/memeparts/" + json.attributes[1].value + " hand.png");
                setHand2("/memeparts/" + json.attributes[1].value + " hand2.png");
                console.log(json.attributes.length)
                if(json.attributes.length === 6)
                {
                    setEyes("/memeparts/nft parts/eyes/" + json.attributes[3].value + ".png");
                    setMouth("/memeparts/nft parts/mouth/" + json.attributes[4].value + ".png");
                    setShirt("/memeparts/nft parts/shirt/" + json.attributes[5].value + ".png");
                    setTattoo("/memeparts/nft parts/tattoo/" + json.attributes[2].value + ".png");
                }
                else if(json.attributes.length === 5){
                    if(json.attributes[2].trait_type == "Tattoo")
                    {
                        setEyes("/memeparts/nft parts/eyes/" + json.attributes[3].value + ".png");
                        setMouth("/memeparts/nft parts/mouth/" + json.attributes[4].value + ".png");
                        setTattoo("/memeparts/nft parts/tattoo/" + json.attributes[2].value + ".png");
                        setShirt(none);
                    }
                    else{
                    setEyes("/memeparts/nft parts/eyes/" + json.attributes[2].value + ".png");
                    setMouth("/memeparts/nft parts/mouth/" + json.attributes[3].value + ".png");
                    setShirt("/memeparts/nft parts/shirt/" + json.attributes[4].value + ".png");
                    setTattoo(none);
                    }
                }else {
                    setEyes("/memeparts/nft parts/eyes/" + json.attributes[2].value + ".png");
                    setMouth("/memeparts/nft parts/mouth/" + json.attributes[3].value + ".png");
                    setTattoo(none);
                    setShirt(none);
                }
            }
            else
            {
                //check 1of1 folder
                setBackground("/memeparts/nft parts/1of1/bg/bg_" + json.attributes[0].value + ".png");
                setBg("/memeparts/nft parts/1of1/bg/bg_" + json.attributes[0].value + ".png");
                setBody("/memeparts/nft parts/1of1/body/" + json.attributes[0].value + ".png");
                setHand("/memeparts/" + json.attributes[0].value + " hand.png");
                setHand2("/memeparts/" + json.attributes[0].value + " hand2.png");
                setEyes("/memeparts/nft parts/1of1/eyes/" + json.attributes[0].value + ".png");
                setMouth("/memeparts/nft parts/1of1/mouth/" + json.attributes[0].value + ".png");
                setShirt("/memeparts/nft parts/1of1/shirt/" + json.attributes[0].value + ".png" ? "/memeparts/nft parts/1of1/shirt/" + json.attributes[0].value + ".png" : none);
                setTattoo("/memeparts/nft parts/1of1/tattoo/" + json.attributes[0].value + ".png" ? "/memeparts/nft parts/1of1/tattoo/" + json.attributes[0].value + ".png" : none);
                setHat("/memeparts/nft parts/1of1/hat/" + json.attributes[0].value + ".png" ? "/memeparts/nft parts/1of1/hat/" + json.attributes[0].value + ".png" : none)

            }
            setCurrentNft(num);
            
           } catch(e) {
            console.error(e);
          }

    }
    const updateImage = async () => {
        let b64t = "";
        if(mug != none)
        {
            if(mug.includes("2hands"))
            {
                b64t = await mergeImages([background, body, tattoo, eyes, mouth, shirt, hat, mug, hand, hand2, effect]);
            }
            else if (mug.includes("hand2"))
            {
                b64t = await mergeImages([background, body, tattoo, eyes, mouth, shirt, hat, mug, hand2, effect]);
            }
            else if (mug.includes("nohands"))
            {
                b64t = await mergeImages([background, body, tattoo, eyes, mouth, shirt, hat, mug, effect]);
            }
            else
            {
                b64t = await mergeImages([background, body, tattoo, eyes, mouth, shirt, hat, mug, hand, effect]);
            }
            
        } else {
            b64t = await mergeImages([background, body, tattoo, eyes, mouth, shirt, hat, effect]);
        }
        setB64(b64t);
        setImage(<img src={b64t} style={{width: "100%", display: "block"}} alt={"Golferz"}/>);
    }
    useEffect(() => {
        checkWalletConnection();
        if(currentAccount)
        {
            getNFTs();
        }
    }, [currentAccount, currentChain])
    useEffect(() => {
        updateImage();
    }, [currentNft, hat, mug, background, effect])


        return(
                <>
                <BrowserView>
                
                    <div style={{width: "60%", backgroundColor:"rgba(255,255,255,0.4", margin:"auto", height: "auto", position:"absolute", top: "0px", left: "20%"}}>
                    <NavBar />
                    <div style={{padding: "25px"}}>
                        <Col>
                            <Row>
                                <Title>The Media Range</Title>
                                <h4>Create and download media for Golferz!</h4>
                            </Row>
                            <Row>
                                
                            </Row>
                            
                            <SizedBox height={"25px"} />
                            <h2>Create images for your socials using your Golfer!</h2>
                            <h3>Choose your Golfer:</h3>
                            <Col>
                                    <div style={{ padding: "5px", backgroundColor: "#C0D6A2", width: "80%", height: "15%", margin: "auto"}}>
                                        {currentAccount ?
                                        <>
                                            {nftList ? <div>{nftList}</div> : <p>Looks like there are no Golferz NFTs in this wallet! Try a different one or putchase a Golferz NFT to use this feature!</p>}
                                        </>
                                        :    
                                            <MediumButton text={"Connect Wallet"} onClick={connectWalletHandler} width={"50%"} />}
                                    </div>
                                </Col>
                                <SizedBox height={"25px"} />
                            <Row>
                                <Col>
                                { image ? <h4>Click on the image to download it!</h4> : <></>}
                                <div style={{ padding: "5px", width: "30%", margin: "auto"}}>
                                            
                                        <div style={{position: "relative"}}>
                                        <a href={b64} download="Golferz.png">{image} </a>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                            </Row>
                            <SizedBox height={"25px"} />
                            { image ? <h3>Choose accessories:</h3> : <></>}
                            <Row>
                            <Col>
                            <div style={{ padding: "20px", backgroundColor: "#C0D6A2", width: "80%", margin: "auto", zIndex: "-100"}}>
                                {image ? 
                                <Col>
                                    <Row>
                                    <Col></Col>
                                    <Col>
                                    <img src={"ItemButtons/coin.png"} width={"100%"} style={{cursor:"pointer"}} onClick={() => {changePartsTray("coin");}}/>
                                    </Col>
                                    <Col>
                                    <img src={"ItemButtons/sats.png"} width={"100%"} style={{cursor:"pointer"}} onClick={() => {changePartsTray("sats");}}/>
                                    </Col>
                                    <Col>
                                    <img src={"ItemButtons/mug.png"} width={"100%"} style={{cursor:"pointer"}} onClick={() => {changePartsTray("mug");}}/>
                                    </Col>
                                    <Col>
                                    <img src={"ItemButtons/hat.png"} width={"100%"} style={{cursor:"pointer"}} onClick={() => {changePartsTray("hat");}}/>
                                    </Col>
                                    <Col>
                                    <img src={"ItemButtons/backgrounds.png"} width={"100%"} style={{cursor:"pointer"}} onClick={() => {changePartsTray("backgrounds");}}/>
                                    </Col>
                                    {/*<Col>
                                    <img src={"ItemButtons/effects.png"} width={"100%"} style={{cursor:"pointer"}} onClick={() => {changePartsTray("effects");}}/>
                                    </Col>*/}
                                    <Col></Col>

                                    </Row>
                                    <SizedBox height={"25px"} />
                                    <Row>
                                        <Col>
                                            {partsTray}
                                        </Col>
                                    </Row>
                                </Col> 
                                : <p>choose a Golfer first!</p>}
                            </div>
                            </Col>
                            </Row>
                            <SizedBox height={"50px"} />
                            <Row>
                                <Col>
                                    <h2>Trezor Home Screens</h2>
                                    <h3>Custom home screens for your Trezor hardware wallets!</h3>
                                </Col>
                            </Row>
                            <SizedBox height={"20px"} />
                            <Row>
                                <Col><a href={"trezor/1.png"} download="Trezor Golferz 1.png"><img src={"trezor/1.png"} width={"60%"}/></a></Col>
                                <Col><a href={"trezor/2.png"} download="Trezor Golferz 2.png"><img src={"trezor/2.png"} width={"60%"}/></a></Col>
                                <Col><a href={"trezor/3.png"} download="Trezor Golferz 3.png"><img src={"trezor/3.png"} width={"60%"}/></a></Col>
                            </Row>
                            <SizedBox height={"30px"} />
                                    <h4>Click on an image to download it!</h4>
                            <SizedBox height={"75px"} />
                            <SocialBar width={"20%"} />
                            <SizedBox height="25px" />
                        </Col>
                    </div>
                    </div>
                    </BrowserView>



                    <MobileView>    
                    <div style={{width: "90%", backgroundColor:"rgba(255,255,255,0.4", margin:"auto", height: "auto", position:"absolute", top: "0px", left: "5%"}}>
                    <NavBar />
                    <div style={{padding: "25px"}}>
                        <Col>
                            <Row>
                                <h1>The Media Range</h1>
                                <h5>Create and download media for Golferz!</h5>
                            </Row>
                            <Row>
                                
                            </Row>
                            
                            <SizedBox height={"25px"} />
                            <h4>Create images for your socials using your Golfer!</h4>
                            <h5>Choose your Golfer:</h5>
                            <Col>
                                    <div style={{ padding: "5px", backgroundColor: "#C0D6A2", width: "90%", height: "15%", margin: "auto"}}>
                                        {currentAccount ? 
                                        <>
                                            {nftList ? <div>{nftList}</div> : <p>Looks like there are no Golferz NFTs in this wallet! Try a different one or putchase a Golferz NFT to use this feature!</p>}
                                        </>
                                        :    
                                            <MediumButton text={"Connect Wallet"} onClick={connectWalletHandler} width={"90%"} />}
                                    </div>
                                </Col>
                                <SizedBox height={"25px"} />
                            <Row>
                                <Col>
                                { image ? <h4>Click on the image to download it!</h4> : <></>}
                                <div style={{ padding: "5px", width: "30%", margin: "auto"}}>
                                            
                                        <div style={{position: "relative"}}>
                                        <a href={b64} download="Golferz.png">{image} </a>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                            </Row>
                            <SizedBox height={"25px"} />
                            { image ? <h5>Choose accessories:</h5> : <></>}
                            <Row>
                            <Col>
                            <div style={{ padding: "5px", backgroundColor: "#C0D6A2", width: "90%", margin: "auto"}}>
                                {image ? 
                                <Col>
                                    <Row>
                                    <Col>
                                    <img src={"ItemButtons/halloween.png"} width={"100%"} style={{cursor:"pointer"}} onClick={() => {changePartsTray("halloween");}} />
                                    </Col>
                                    <Col>
                                    <img src={"ItemButtons/sats.png"} width={"100%"} style={{cursor:"pointer"}} onClick={() => {changePartsTray("sats");}}/>
                                    </Col>
                                    <Col>
                                    <img src={"ItemButtons/mug.png"} width={"100%"} style={{cursor:"pointer"}} onClick={() => {changePartsTray("mug");}}/>
                                    </Col>
                                    <Col>
                                    <img src={"ItemButtons/hat.png"} width={"100%"} style={{cursor:"pointer"}} onClick={() => {changePartsTray("hat");}}/>
                                    </Col>
                                    <Col>
                                    <img src={"ItemButtons/backgrounds.png"} width={"100%"} style={{cursor:"pointer"}} onClick={() => {changePartsTray("backgrounds");}}/>
                                    </Col>
                                    <Col>
                                    <img src={"ItemButtons/effects.png"} width={"100%"} style={{cursor:"pointer"}} onClick={() => {changePartsTray("effects");}}/>
                                    </Col>
                                    </Row>
                                    <SizedBox height={"25px"} />
                                    <Row>
                                        <Col>
                                            {partsTray}
                                        </Col>
                                    </Row>
                                </Col> 
                                : <p>choose a Golfer first!</p>}
                            </div>
                            </Col>
                            </Row>
                            <SizedBox height={"50px"} />
                            <Row>
                                <Col>
                                    <h4>Trezor Home Screens</h4>
                                    <h5>Custom home screens for your Trezor hardware wallets!</h5>
                                </Col>
                            </Row>
                            <SizedBox height={"20px"} />
                            <Row>
                                <Col><a href={"trezor/1.png"} download="Trezor Golferz 1.png"><img src={"trezor/1.png"} width={"95%"}/></a></Col>
                                <Col><a href={"trezor/2.png"} download="Trezor Golferz 2.png"><img src={"trezor/2.png"} width={"95%"}/></a></Col>
                                <Col><a href={"trezor/3.png"} download="Trezor Golferz 3.png"><img src={"trezor/3.png"} width={"95%"}/></a></Col>
                            </Row>
                            <SizedBox height={"30px"} />
                                    <h4>Click on an image to download it!</h4>
                            <SizedBox height={"50px"} />
                            <SocialBar width={"40%"} />
                            <SizedBox height="25px" />
                        </Col>
                    </div>
                    </div>
                    </MobileView>
            </>
        )
}