import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import mergeImages from 'merge-images';
import shadow from "./media/Shadow.png";
import shadowWoman from "./media/Shadow Woman.png";
import hand from "./media/shadow hand.png";
import hand2 from "./media/shadow hand 2.png";
import halloweenBadge from "./media/Halloween Badge.png";
import christmasBadge from "./media/Christmas Badge.png";
import { BrowserView, MobileView } from "react-device-detect";
import { SizedBox } from "./SizedBox";


export default function ImageTray({dir, onClick, bg, type, currentItem}) {

  const none = "/memeparts/nft parts/tattoo/1none.png";
  const [selectedItem, setSelectedItem] = useState(none);
  const [page, setPage] = useState(0);
  const d = new Date();
  const isHalloween = d.getMonth() == 9;
  const isChristmas = d.getMonth() == 11;

// Modify the onClick function
const handleItemClick = (src) => {
  if (selectedItem === src) {
    setSelectedItem("/memeparts/nft parts/" + dir +"/1none.png");
    onClick("/memeparts/nft parts/" + dir +"/1none.png");
  } else {
    setSelectedItem(src);
    onClick(src);
  }
};

    function importAll(r) {
        let images = {};
         r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
        return r.keys();
       }
       let images = [];
       let imgtiles = [];
       let originalImages = [];

       //this is fucking stupid but for some reason images = importAll(require.context('../../public/memeparts/' + dir, false, /\.(png|jpe?g|svg)$/)) doesn't work so switch it is...
       switch(dir){
        //media generator
        case "mug":
          originalImages = importAll(require.context('../../public/memeparts/mug', false, /\.(png|jpe?g|svg)$/));

          if (isHalloween) {
            let hwImages = importAll(require.context('../../public/memeparts/mug/halloween', false, /\.(png|jpe?g|svg)$/));
            let halloweenImages = hwImages.map(img => "./halloween/" + img.slice(2));
            images = halloweenImages.concat(originalImages);

          } else if (isChristmas)
          {
            let hwImages = importAll(require.context('../../public/memeparts/mug/christmas', false, /\.(png|jpe?g|svg)$/));
            let halloweenImages = hwImages.map(img => "./christmas/" + img.slice(2));
            images = halloweenImages.concat(originalImages);
          } else {
            images = originalImages;
          }
          break;
            case "coin":
                images = importAll(require.context('../../public/memeparts/coin', false, /\.(png|jpe?g|svg)$/));
                break;
                case "sats":
                images = importAll(require.context('../../public/memeparts/sats', false, /\.(png|jpe?g|svg)$/));
                break;
        case "hat":
          originalImages = importAll(require.context('../../public/memeparts/hat', false, /\.(png|jpe?g|svg)$/));

          if (isHalloween) {
            let hwImages = importAll(require.context('../../public/memeparts/hat/halloween', false, /\.(png|jpe?g|svg)$/));
            let halloweenImages = hwImages.map(img => "./halloween/" + img.slice(2));
            images = halloweenImages.concat(originalImages);

          } else if (isChristmas)
          {
            let hwImages = importAll(require.context('../../public/memeparts/hat/christmas', false, /\.(png|jpe?g|svg)$/));
            let halloweenImages = hwImages.map(img => "./christmas/" + img.slice(2));
            images = halloweenImages.concat(originalImages);
          } else {
            images = originalImages;
          }
            break;
        case "backgrounds":
          originalImages = importAll(require.context('../../public/memeparts/backgrounds', false, /\.(png|jpe?g|svg)$/));

          if (isHalloween) {
            let hwImages = importAll(require.context('../../public/memeparts/backgrounds/halloween', false, /\.(png|jpe?g|svg)$/));
            let halloweenImages = hwImages.map(img => "./halloween/" + img.slice(2));
            images = halloweenImages.concat(originalImages);
          } else if (isChristmas)
          {
            let hwImages = importAll(require.context('../../public/memeparts/backgrounds/christmas', false, /\.(png|jpe?g|svg)$/));
            let halloweenImages = hwImages.map(img => "./christmas/" + img.slice(2));
            images = halloweenImages.concat(originalImages);
          } else {
            images = originalImages;
          }
            images.unshift(bg);
            break;
        case "effects":
            images = importAll(require.context('../../public/memeparts/effects', false, /\.(png|jpe?g|svg)$/));
            break;


          //golferz NFT
          case "nft parts/bg":
            images = importAll(require.context('../../public/memeparts/nft parts/bg', false, /\.(png|jpe?g|svg)$/));
            break;
          case "nft parts/body":
            images = importAll(require.context('../../public/memeparts/nft parts/body', false, /\.(png|jpe?g|svg)$/));
            break;
          case "nft parts/tattoo":
            images = importAll(require.context('../../public/memeparts/nft parts/tattoo', false, /\.(png|jpe?g|svg)$/));
            break;
          case "nft parts/eyes":
            images = importAll(require.context('../../public/memeparts/nft parts/eyes', false, /\.(png|jpe?g|svg)$/));
            break;
          case "nft parts/mouth":
            images = importAll(require.context('../../public/memeparts/nft parts/mouth', false, /\.(png|jpe?g|svg)$/));
            break;
          case "nft parts/shirt":
            images = importAll(require.context('../../public/memeparts/nft parts/shirt', false, /\.(png|jpe?g|svg)$/));
            break;


            //golferz womans
            case "nft parts woman/bg":
            images = importAll(require.context('../../public/memeparts/nft parts woman/bg', false, /\.(png|jpe?g|svg)$/));
            break;
          case "nft parts woman/body":
            images = importAll(require.context('../../public/memeparts/nft parts woman/body', false, /\.(png|jpe?g|svg)$/));
            break;
          case "nft parts woman/hair":
            images = importAll(require.context('../../public/memeparts/nft parts woman/hair', false, /\.(png|jpe?g|svg)$/));
            break;
          case "nft parts woman/eyes":
            images = importAll(require.context('../../public/memeparts/nft parts woman/eyes', false, /\.(png|jpe?g|svg)$/));
            break;
          case "nft parts woman/mouth":
            images = importAll(require.context('../../public/memeparts/nft parts woman/mouth', false, /\.(png|jpe?g|svg)$/));
            break;
          case "nft parts woman/shirt":
            images = importAll(require.context('../../public/memeparts/nft parts woman/shirt', false, /\.(png|jpe?g|svg)$/));
            break;
        }


        images.forEach(img =>{
            let src = img;
            let addHand = false;
            let addHand2 = false;
            let partName = img;
            if(img != bg){
                src = "/memeparts/" + dir + "/" + img.slice(2);
                let imgName = img.slice(2);
                const parts = imgName.split('_');
                if(parts[1]){
                  if (parts[1].includes('.png'))
                  {
                    parts[1] = parts[1].slice(0, -4);
                  }
                  partName = parts[1];
                } else 
                {
                  parts[0] = parts[0].slice(0, -4);
                  partName = parts[0];
                }
                
            }
            else
            {
                partName = bg.split('/')[4].slice(3, -4);
            }
            if(partName === "1none")
            {
                partName = "NONE"
            }

            const imgContainerStyle = {
                position: "relative"
              };
            
              if(!dir.includes("nft"))
              {
                if (dir === "coin" || dir === "sats") {
                  addHand = true;
                } else {
                  if(img.includes("mug")){
                  if (img.includes("_2hands"))
                  {
                      addHand = true;
                      addHand2 = true;
                  }
                  else if (img.includes("hand2")) {
                    addHand2 = true;
                  } 
                  else if (img.includes("nohands"))
                  {
                    addHand = false;
                    addHand2 = false;
                  }
                  else
                  {
                    addHand = true;
                  }
                }
                if (partName == "NONE")
                {
                  addHand = false; 
                }
              }
            }
              const shadowStyle = {
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                cursor: "pointer",
                zIndex: dir == "backgrounds" || dir == "nft parts/bg" ? 3 : 1
              };

              const textContainerStyle = {
                textAlign: "center",
                padding:"10px"
              };

              const mainImageStyle = {
                position: "relative",
                zIndex: 1,
                cursor: "pointer",
                width: "100%"
              };

              const handStyle = 
              {
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                cursor: "pointer",
                zIndex: 3
              }
            
              imgtiles.push(
                <Col 
                style={{
                  ...textContainerStyle,
                  backgroundColor: selectedItem === src ? '#00000055' : 'transparent' // Change background color if item is selected
                }}  
                onClick={() => handleItemClick(src)}
              >
                  <div style={imgContainerStyle}>
                    {type == "woman" ? <img src={shadowWoman} style={shadowStyle} /> :<img src={shadow} style={shadowStyle} />}
                    <img src={src}  style={mainImageStyle}/>
                    {addHand ? <img src={hand} style={handStyle} /> : <></>}
                    {addHand2 ? <img src={hand2} style={handStyle} /> : <></>}
                    {img.includes("halloween")? <img src={halloweenBadge} style={handStyle}/>:<></>}
                    {img.includes("christmas")? <img src={christmasBadge} style={handStyle}/>:<></>}
                  </div>
                  <BrowserView>
                  <p>{partName}</p>
                  </BrowserView>
                  <MobileView>
                  <p style={{ fontSize: '8px' }}>{partName}</p>
                  </MobileView>

                </Col>
              );
        })


        let rows = [];
        let cols = [];
        let pages = [];

        for(let i = 0; i < imgtiles.length; i++)
        {
            cols.push(imgtiles[i]);

            if(cols.length == 5)
            {
                rows.push(
                    <Row>
                        {cols}
                    </Row>);
                cols = [];
                if(rows.length == 3)
                {
                  pages.push(rows);
                  rows = [];
                }
            }

        }
        for(let j = cols.length; j < 5; j++)
        {
            cols.push(<Col></Col>);
        }
        rows.push(<Row>{cols}</Row>);
        if(rows.length > 0)
          pages.push(rows);

        useEffect(() => {
                  setSelectedItem(currentItem);
                  setPage(0);
        },[dir])

        function changePage(num)
        {
          if(pages.length > page + num && page + num >= 0 )
          {
            setPage(page + num)
          }
        }
    return(
        <div>
        {pages[page]}
        {pages.length > 1 ?
        <>
        <Row><Col><button onClick={()=>{
          changePage(-1);
        }}> Prev</button></Col>
        <Col><button onClick={()=>{
          changePage(1);
        }}>Next</button></Col>
        </Row>
        
        </> : <></>}
        </div>
        
    )
}