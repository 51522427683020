import {useState, useEffect} from 'react';
import twitter from "./media/twitter.png";
import discord from "./media/discord.png";
import telegram from "./media/telegram.png";

export default function SocialButton({type, width}) {
    const [buttonImg, setButtonImg] = useState("");
    const [buttonLink, setButtonLink] = useState("");
    useEffect(() => {
    switch(type){
        case "twitter":
            setButtonLink("https://twitter.com/golferzNFT");
            setButtonImg(twitter);
            break;
        case "discord":
            setButtonLink("https://discord.gg/376hFfFGH3");
            setButtonImg(discord);
            break;

        case "telegram":
            setButtonLink("https://t.me/Golferz");
            setButtonImg(telegram);
            break
        default:
            console.log("Social button type did not match any known social media");
            break;
    }
},[]);

    return(
    <a href={buttonLink} >
        <img src={buttonImg} width={width ? width : "40%"} alt="social link"/>
    </a>
    );
}