import {Col, Row} from "react-bootstrap";
import GolferzGif from "../components/GolferzGif";
import MediumButton from "../components/MediumButton";
import { SizedBox } from "../components/SizedBox";
import SocialBar from "../components/SocialBar";
import { BrowserView, MobileView } from "react-device-detect";
import { useEffect, useState } from "react";
import { ethers } from "ethers";

import contract from "../contracts/golferzgoerli.json";

const contractAddress = "0xE11Ad1071906Be7473eADEd8F07fdc2EF75aeC3e";
const abi = contract.abi;

export default function Mint() {
    const [currentAccount, setCurrentAccount] = useState(null);

    const checkWalletConnection = async () => {
        const { ethereum } = window;
        if(!ethereum) {
            console.log("You need to have Metamask installed!");
            return;
        } else {
            console.log("Metamask is installed.");
        }

        const accounts = await ethereum.request({method: "eth_accounts" });

        if(accounts.length > 0) {
            const account = accounts[0];
            setCurrentAccount(account);
        }
    }

    const connectWalletHandler = async () => {
        const { ethereum } = window;

        if(!ethereum) {
            console.log("You need to have Metamask installed!");
        } 

        try {
            const accounts = await ethereum.request({method: "eth_requestAccounts"});
            setCurrentAccount(accounts[0]);
        } catch (e) {
            console.log(e);
        }
    }

    const mintNFTHandler = async () => {
        try{
            const { ethereum } = window;

        if(ethereum) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            const nftContract = new ethers.Contract(contractAddress, abi, signer);

            //.05 ETH for whitelist! CHANGE TO .075 WHIILE CONTRACT IS PAUSED FOR PUBLIC MINT
            let nftTxn = await nftContract.mintNFTs(1, {value: ethers.utils.parseEther("0.0001") });

            await nftTxn.wait();
            console.log("minted! See on etherscan: https://goerli.etherscan.io/tx/${nftTxn.hash}");
        }

        } catch(e) {
            console.log(e);
        }

    }


    useEffect(() => {
        checkWalletConnection();
    }, [])

        return(
                <>
                <BrowserView>
                    <div style={{width: "60%", backgroundColor:"rgba(255,255,255,0.4", margin:"auto", height: "auto", position:"absolute", top: "0px", left: "20%", padding: "25px"}}>
                        <Col>
                            <Row>
                                <h1>⛳Golferz⛳</h1>
                            </Row>
                            <Row>
                                <h3>A collection of 5,555 Golferz NFTs</h3>
                                <h3>On the Goerli Testnet!</h3>
                            </Row>
                            <SizedBox height={"25px"} />
                            <Row>
                                <Col>
                                    <div style={{ padding: "5px", backgroundColor: "black", width: "30%", margin: "auto"}}>
                                        <GolferzGif/>
                                    </div>
                                </Col>
                            </Row>
                                <SizedBox height={"25px"} />
                                <Row>
                                    <Col>
                                        {currentAccount}
                                    </Col>
                                </Row>
                            <Row>
                                <Col>
                                    {currentAccount ?  <MediumButton onClick={mintNFTHandler} text="Mint NFTs" /> : <MediumButton onClick={connectWalletHandler} text="Connect Wallet" />}
                                </Col>
                            </Row>
                            <SizedBox height={"25px"} />
                            <Row>
                                <h4>Mint Price: 0.0001 GoerliETH</h4>
                            </Row>
                            <SizedBox height={"50px"} />
                            <Row>
                                <h1>Coming Soon</h1>
                                <SizedBox height={"25px"} />
                                <h2>🏌️‍♂️ Battle Golf 🏌️‍♂️</h2>
                                <p>Battle Golf is a 3v3 head-to-head competition where players try to sink the ball into the enemy cup. Compete and win rewards such as NFTs and tokens!</p>
                                <SizedBox height={"25px"} />
                                <h2>🏌️‍♀️ Cartz 🏌️‍♀️</h2>
                                <p>Golferz get around the course and play golf on Cartz, which will be airdropped to all holders when Battle Golf is released. Carts will have different abilities and stats that can be leveled up to play a more extreme and chaotic game of Battle Golf</p>
                            </Row>
                            <SizedBox height={"75px"} />
                            <SocialBar width={"30%"} />
                            <SizedBox height="25px" />
                        </Col>
                    </div>
                    </BrowserView>
                    <MobileView>
                    <div style={{width: "90%", backgroundColor:"rgba(255,255,255,0.4", margin:"auto", height: "auto", position:"absolute", top: "0px", left: "5%", padding: "25px"}}>
                        <Col>
                            <Row>
                                <h1>⛳Golferz⛳</h1>
                            </Row>
                            <Row>
                                <h3>A collection of 5,555 Golferz NFTs</h3>
                            </Row>
                            <SizedBox height={"25px"} />
                            <Row>
                                <Col>
                                    <div style={{ padding: "5px", backgroundColor: "black", width: "70%", margin: "auto"}}>
                                        <GolferzGif/>
                                    </div>
                                </Col>
                            </Row>
                                <SizedBox height={"25px"} />
                            <Row>
                                <Col>
                                <MediumButton text={"Minting Soon"} width={"80%"} />
                                </Col>
                            </Row>
                            <SizedBox height={"25px"} />
                            <Row>
                                <h4>Mint Price: 0.0001 GoerliETH</h4>
                            </Row>
                            <SizedBox height={"50px"} />
                            <Row>
                                <h1>Coming Soon</h1>
                                <SizedBox height={"25px"} />
                                <h2>🏌️‍♂️ Battle Golf 🏌️‍♂️</h2>
                                <p>The Golferz like to play Battle Golf, a 3v3 head-to-head competition where players try to sink the ball into the enemy cup. Compete and win rewards such as NFTs and tokens!</p>
                                <SizedBox height={"25px"} />
                                <h2>🏌️‍♀️ Cartz 🏌️‍♀️</h2>
                                <p>Golferz get around the course and play golf on Cartz, which will be airdropped to all holders when Battle Golf is released. Carts will have different abilities and stats that can be leveled up to play a more extreme and chaotic game of Battle Golf</p>
                            </Row>
                            <SizedBox height={"75px"} />
                            <SocialBar width={"80%"} />
                            <SizedBox height="25px" />
                        </Col>
                    </div>
                </MobileView>
            </>
        )
}