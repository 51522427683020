import {Col, Row} from "react-bootstrap";
import GolferzGif from "../components/GolferzGif";
import MediumButton from "../components/MediumButton";
import { SizedBox } from "../components/SizedBox";
import SocialBar from "../components/SocialBar";
import { BrowserView, MobileView } from "react-device-detect";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import Emblem from "../components/media/GolferzEmblem.png";
import styled from "styled-components";
import contract from "../contracts/golferz.json";
import Chevron from "react-chevron";
import NavBar from "../components/NavBar";

const contractAddress = "0x5308a8c8a72c3ef8940ac929684b7065a1f732b1";
const abi = contract.abi;

const Title = styled.h1`
font-size: 3.5em;
`

export default function Mint() {
    const [currentAccount, setCurrentAccount] = useState(null);
    const [isContractPaused, setIsContractPaused] = useState(true);
    const [isWhitelist, setIsWhitelist] = useState(null);
    const [price, setPrice] = useState(0.05);
    const [buttonText, setButtonText] = useState("Connect Wallet");
    const [banner, setBanner] = useState("Connect your Metamask wallet!");
    const [status, setStatus] = useState("Mint has not started");
    const [count, setCount] = useState(1);
    const [list, setList] = useState([]);

    const checkWalletConnection = async () => {
        const { ethereum } = window;
        if(!ethereum) {
            console.log("You need to have Metamask installed!");
            return;
        } else {
            console.log("Metamask is installed.");
        }

        const accounts = await ethereum.request({method: "eth_accounts" });

        if(accounts.length > 0) {
            const account = accounts[0];
            setCurrentAccount(account);
            setBanner(currentAccount);
        }
    }

    const handleClick = async () => {
        if(currentAccount)
        {
            if(!isContractPaused)
            {
                if(isWhitelist)
                {
                    const { ethereum } = window;
                    const provider = new ethers.providers.Web3Provider(ethereum);
                    const nftcontract = new ethers.Contract(contractAddress, abi, provider);

                    let WL = await nftcontract.whitelist(currentAccount);
                    let isWL = parseInt(WL._hex, 16);
                    console.log(isWL);
                    if(isWL != 0)
                    {
                        mintNFTHandler(count);
                    }
                    else{
                        alert("You are not on the whitelist, champ. Wait until the public mint!");
                    }

                }
                else{
                    mintNFTHandler(count);
                }
            }  
            else
            {
                alert("Sorry, sir, we're closed.");
            }
        }
        else
        {
            connectWalletHandler();
        }
    }

    const connectWalletHandler = async () => {
        const { ethereum } = window;

        if(!ethereum) {
            console.log("You need to have Metamask installed!");
        } 

        try {
            const accounts = await ethereum.request({method: "eth_requestAccounts"});
            setCurrentAccount(accounts[0]);
        } catch (e) {
            console.log(e);
        }
    }

    const mintNFTHandler = async (count) => {
        try{
            const { ethereum } = window;
        if(ethereum) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            const nftContract = new ethers.Contract(contractAddress, abi, signer);
            console.log(ethers.utils.formatEther(((count*price)).toString()));
            let balanceInEth = 0;
            await provider.getBalance(currentAccount).then((balance) => {
                balanceInEth = ethers.utils.formatEther(balance)
                console.log(`balance: ${balanceInEth} ETH`)
            })

            
               if(balanceInEth > parseFloat((price * count)/1000000000000000000).toFixed(2))
               {
                let nftTxn = await nftContract.mintNFTs(count, {value: ethers.utils.parseEther(((count*price/1000000000000000000).toFixed(2)).toString()) });

                await nftTxn.wait();
                setStatus("minted " + count + "nfts!");
               }
               else{
                alert("Sorry, pal, I can't give credit. Come back when you're a little, mmm... RICHER!");
                console.log(ethers.utils.parseEther(((count*price).toFixed(2)).toString()));
                console.log(balanceInEth);
               }
               let totalMinted = await nftContract.totalSupply();
               //setBanner(totalMinted + "/5555 Minted");
        }

        } catch(e) {
            console.log(e);
        }

    }
    const checkContractData = async () => {
        try{
        const { ethereum } = window;
        const provider = new ethers.providers.Web3Provider(ethereum);
        const nftcontract = new ethers.Contract(contractAddress, abi, provider)

        let newPause = await nftcontract.isPaused();
        let newWL = await nftcontract.isWhitelistMint();
        setIsContractPaused(newPause);
        setIsWhitelist(newWL);
        let totalMinted = await nftcontract.totalSupply();
        //setBanner(totalMinted + "/5555 Minted");

        let newPrice = newWL? await nftcontract.WHITELIST_PRICE() : await nftcontract.PRICE();
        setPrice(newPrice);
        console.log(newWL);
        if(newWL) {
            setStatus("Mint is currently open to whitelisted wallets");
        }
        else{
            setStatus("Mint is public");
        }
        if(newPause){
            setStatus("Mint is not active at this time");
        }
        else{
            setButtonText("Mint for " + parseFloat((newPrice * count)/1000000000000000000).toFixed(2) + "eth");
        }
        } catch (e) {
            console.log(e);
        }
        if (contractAddress === "") {
            setStatus("Mint has not started");
        }
        
    }

    const changeAmount = (change) => {
        let num = count + change;
        console.log(num)
        if(num > 10)
        {
            num = 10;
        }
        else if(num == 0)
        {
            num = 1;
        }
        setCount(num);
        setButtonText("Mint for " + parseFloat((price * num)/1000000000000000000).toFixed(2) + " eth")
        console.log(price + " " + count);
    }

    useEffect(() => {
        checkWalletConnection();
        checkContractData();
    }, [])

        return(
                <>
                <BrowserView>
                    <div style={{width: "60%", backgroundColor:"rgba(255,255,255,0.4", margin:"auto", height: "auto", position:"absolute", top: "0px", left: "20%"}}>
                    <NavBar />
                    <div style={{padding: "25px"}}>
                        <Col>
                            <Row>
                                <Title>⛳Golferz⛳</Title>
                            </Row>
                            <Row>
                                <h3>A collection of 5,555 Golferz NFTs</h3>
                            </Row>
                            <SizedBox height={"25px"} />
                            <Row>
                                <Col>
                                    <div style={{ padding: "5px", backgroundColor: "black", width: "30%", margin: "auto"}}>
                                        <GolferzGif/>
                                    </div>
                                </Col>
                            </Row>
                                <SizedBox height={"25px"} />
                                <Col>
                                    {banner}
                                </Col>
                            <Row>
                                <Col>
                                {isContractPaused ? <></> :
                                    <h2> <span style={{cursor: "pointer"}} onClick={() => {changeAmount(-1)}}><Chevron direction="left" /></span> Mint {count} NFTs <span onClick={() => {changeAmount(1)}} style={{cursor: "pointer"}}><Chevron direction="right" /></span> </h2>}
                                </Col>
                            </Row>
                            <Row>
                                
                                <Col>
                                    <MediumButton text={buttonText} onClick={handleClick} width={"50%"} />
                                </Col>
                            </Row>
                            {status}
                            <SizedBox height={"25px"} />
                            <Row>
                                {/*<h4>Mint Price: 0.03 eth</h4>*/}
                            </Row>
                            <SizedBox height={"50px"} />
                            <Col>
                            <img src={Emblem} width={"35%"}/>
                            </Col>
                            <SizedBox height={"100px"} />
                            <Row>
                                <h1>Coming Soon</h1>
                                <SizedBox height={"25px"} />
                                <h2>🏌️‍♂️ Golferz64 🏌️‍♂️</h2>
                                <p>Golferz64 (working title) is a P2HF P2E casual arcade golf game where players can compete with friends and earn NFTs to use on the courses!</p>
                                <SizedBox height={"25px"} />
                                <h2>🏌️‍♀️ Golferz Gear 🏌️‍♀️</h2>
                                <p>When you win games of Golferz64, you may also win equipment NFTs like balls, headcovers, clubs, or carts that you can use to flex on the competition on and off the course!</p>
                            </Row>
                            <SizedBox height={"75px"} />
                            <SocialBar width={"20%"} />
                            <SizedBox height="25px" />
                        </Col>
                    </div>
                    </div>
                    </BrowserView>
                    <MobileView>
                    <div style={{width: "90%", backgroundColor:"rgba(255,255,255,0.4", margin:"auto", height: "auto", position:"absolute", top: "0px", left: "5%"}}>
                    <NavBar/>
                    <div style={{padding: "25px"}}>
                        <Col>
                            <Row>
                                <h1>⛳Golferz⛳</h1>
                            </Row>
                            <Row>
                                <h3>A collection of 5,555 Golferz NFTs</h3>
                            </Row>
                            <SizedBox height={"25px"} />
                            <Row>
                                <Col>
                                    <div style={{ padding: "5px", backgroundColor: "black", width: "70%", margin: "auto"}}>
                                        <GolferzGif/>
                                    </div>
                                </Col>
                            </Row>
                                <SizedBox height={"25px"} />
                            <Row>
                                <SizedBox height={"25px"} />
                                <Col>
                                    {banner}
                                </Col>
                            <Row>
                                <Col>
                                {isContractPaused ? <></> :
                                    <h2> <span style={{cursor: "pointer"}} onClick={() => {changeAmount(-1)}}><Chevron direction="left" /></span> Mint {count} NFTs <span onClick={() => {changeAmount(1)}} style={{cursor: "pointer"}}><Chevron direction="right" /></span> </h2>}
                                </Col>
                            </Row>
                                <Col>
                                    <MediumButton text={buttonText} onClick={handleClick} width={"50%"} />
                                </Col>
                            </Row>
                            <SizedBox height={"25px"} />
                            <Row>
                                {/*<h4>Mint Price: 0.03 eth</h4>*/}
                            </Row>
                            <SizedBox height={"50px"} />
                            <Col>
                            <img src={Emblem} width={"50%"}/>
                            </Col>
                            <SizedBox height={"50px"} />
                            <Row>
                                <h1>Coming Soon</h1>
                                <SizedBox height={"25px"} />
                                <h2>🏌️‍♂️ Golferz64 🏌️‍♂️</h2>
                                <p>Golferz64 (working title) is a P2HF P2E casual arcade golf game where players can compete with friends and earn NFTs to use on the courses!</p>
                                <SizedBox height={"25px"} />
                                <h2>🏌️‍♀️ Golferz Gear 🏌️‍♀️</h2>
                                <p>When you win games of Golferz64, you may also win equipment NFTs like balls, headcovers, clubs, or carts that you can use to flex on the competition on and off the course!</p>
                            </Row>
                            <SizedBox height={"75px"} />
                            <SocialBar width={"40%"} />
                            <SizedBox height="25px" />
                        </Col>
                    </div>
                    </div>
                </MobileView>
            </>
        )
}