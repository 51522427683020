import mergeImages from 'merge-images';
import { useState, useEffect } from 'react';

export default function Sticker({name, body, eyes, shirt, tattoo, mouth})
{
    
    const [image, setImage] = useState(<></>);

    let baseurl = "/sticker pack/golferz/" + name + "/";
    let base = baseurl + "Base.png";

    async function getImageUrl(baseurl, category, itemName) {
        const url = `${baseurl}${category}/${itemName}`;
        const defaultUrl = `${baseurl}${category}/Base.png`;
        try {
            const response = await fetch(url, {
                method: 'GET'
              });
              const contentType = response.headers.get('Content-Type');
            if (response.ok && contentType?.includes('image/png')) {
            return url;
        } else {
          return defaultUrl;
        }
          return response.ok ? url : defaultUrl;
        } catch (error) {
            console.log(error);
          return defaultUrl;
        }
      }

    const updateImage = async () => {
        let bd = await getImageUrl(baseurl, "body", body);
        let ey = await getImageUrl(baseurl, "eyes", eyes);
        let mo = await getImageUrl(baseurl, "mouth", mouth);
        let sh = await getImageUrl(baseurl, "shirt", shirt);
        let tt = await getImageUrl(baseurl, "tattoo", tattoo);
        let b64t = "";
        b64t = await mergeImages([base, bd, tt, ey, sh, mo]);
        setImage(<img src={b64t} style={{width: "100%", display: "block"}} alt={"Golferz Sticker " + name}/>);
    }

    useEffect(() => {
        updateImage();
    }, [base, body, eyes, tattoo, shirt, mouth])

    return(
        <>
            {image}
        </>
    );
}