import React, { useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import NavBar from '../components/NavBar';
import { Col, Row } from 'react-bootstrap';
import { SizedBox } from '../components/SizedBox';
import styled from 'styled-components';
import MediumButton from '../components/MediumButton';
import SocialBar from '../components/SocialBar';
import { useSearchParams } from 'react-router-dom';
import EmblemTag from "../components/media/GolferzTag.png";

const Title = styled.h1`
font-size: 3.5em;
`

const EthInupit = styled.input`
width: 85%;
font-size: 1.5em;
spellcheck: false;
`

function GolfTag() {
  const [url, setUrl] = useState('');
  const [message, setMessage] = useState('Press the scan button and scan your tag');
  const [scanned, setScanned] = useState(false);
  const [params, setParams] = useSearchParams();
  const [collab, setCollab] = useState(null)
  const [collabUrl, setCollabUrl] = useState(null);

  useEffect(() => {
    switch(params.get("c"))
    {
        case "birdiegolf":
            setCollab("Birdie Golf");
            setCollabUrl("https://web3birdie.io");
            break;
        
        case "headcoverdao":
            setCollab("HeadcoverDAO");
            setCollabUrl("https://www.headcoverdao.com/")
            break;
    }
}, []);


  function handleChange(event) {
    setUrl(event.target.value);
  }

  function handleClick() {
    if(scanned)
    {
        writeNDEFMessage();
    }
    else
    {
        scanNFCTag();
    }
  }

  const scanNFCTag = async() => {
  if ("NDEFReader" in window) {
      try {
          const ndef = new window.NDEFReader();
          await ndef.scan();

          console.log("Scan started successfully.");
          ndef.onreadingerror = () => {
            setMessage("Cannot read data from the NFC tag. Try again");
          };

          ndef.onreading = (event) => {
            setMessage("Golf tag read! Input your ETH address or ENS name and click 'Configure tag'.");
            setScanned(true);
          };
      } catch (error) {
          setMessage(`Error! Scan failed to start: ${error}.`);
      }
  }
};


const writeNDEFMessage = async() => {
  try {
    const ndef = new window.NDEFReader();
    await ndef.write({
      records: [{ recordType: "url", data: "https://opensea.io/" + url }],
    });
    setMessage(`Golf tag successfully set up! Close this website and scan the tag to ensure it opens your OpenSea profile. If not, refresh this page and reconfigure your tag.`);
  } catch (error) {
    setMessage(error);
  }
};

  return (
    <>
    <BrowserView>
                    <div style={{width: "60%", backgroundColor:"rgba(255,255,255,0.4", margin:"auto", height: "auto", position:"absolute", top: "0px", left: "20%"}}>
                    <NavBar collab={collab} url={collabUrl}/>
                    <div style={{padding: "25px"}}>
                        <Col>
                            <Row>
                                <Title>{collab ? collab : "Golferz"} bag tag setup</Title>
                            </Row>
                            <Row>
                                <h3>Configure your new Web3 enabled golf bag tag!</h3>
                                <h5>When others scan your Web3 enabled golf bag tag, they will be taken to your OpenSea profile to quickly share your NFT collection with others!</h5>
                            </Row>
                            <SizedBox height={"25px"} />
                            <Row>
                                
                                <Col>
                                    <MediumButton text={scanned ? "Configure tag" : "Scan your tag"} onClick={handleClick} width={"50%"} />
                                </Col>
                            </Row>
                            <Row>
                            <div>{message}</div>
                            </Row>
                            <SizedBox height={"050px"} />
                            <Row>
                                <Col>
    <div>
      <EthInupit type="text" value={url} placeholder="Enter your eth address or ENS name" onChange={handleChange} />
      
    </div>
                                </Col>

                            </Row>

                            <SizedBox height={"25px"} />
                            <img src={EmblemTag} width={"35%"}/>
                            <Col>
                            </Col>


                            <SizedBox height={"50px"} />
                            <SocialBar width={"20%"} />
                            <SizedBox height="25px" />
                        </Col>
                    </div>
                    </div>
                    </BrowserView>
                    <MobileView>
                    <div style={{width: "90%", backgroundColor:"rgba(255,255,255,0.4", margin:"auto", height: "auto", position:"absolute", top: "0px", left: "5%"}}>
                    <NavBar collab={collab} url={collabUrl}/>
                    <div style={{padding: "25px"}}>
                        <Col>
                            <Row>
                                <h1>{collab ? collab : "Golferz"} bag tag setup</h1>
                            </Row>
                            <Row>
                                <h3>Configure your new Web3 enabled golf bag tag!</h3>
                                <h5>When others scan your Web3 enabled golf bag tag, they will be taken to your OpenSea profile to quickly share your NFT collection with others!</h5>
                            </Row>
                            <SizedBox height={"25px"} />
                            <Row>
                                
                                <Col>
                                    <MediumButton text={scanned ? "Configure tag" : "Scan your tag"} onClick={handleClick} width={"80%"} />
                                </Col>
                            </Row>
                            <Row>
                            <div>{message}</div>
                            </Row>
                            <SizedBox height={"050px"} />
                            <Row>
                                <Col>
    <div>
      <EthInupit type="text" value={url} placeholder="Enter your eth address or ENS" onChange={handleChange} />
      
    </div>
                                </Col>

                            </Row>

                            <SizedBox height={"25px"} />
                            <img src={EmblemTag} width={"35%"}/>
                            <SizedBox height={"50px"} />
                            <Col>
                            </Col>

                            <SocialBar width={"40%"} />
                            <SizedBox height="25px" />
                        </Col>
                    </div>
                    </div>
                    </MobileView>
            </>

  );
}

export default GolfTag;

