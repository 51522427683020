import styled from "styled-components";

const ButtonWrapper = styled.button`
  width: ${(props) => (props.width ? props.width : "30%")};
  height: "auto";
  background-color: white;
  color: black;
  font-weight: bold;
  font-size: 24px;
  padding: 20px 40px 20px 40px;
  border: 2px solid black;
  margin: "auto";
`;

export default function MediumButton({ onClick, text, width }) {
  async function safeOnClick() {
    onClick();
  }

  return (
    <ButtonWrapper width={width} onClick={safeOnClick}>
      {text}
    </ButtonWrapper>
  );
}
